import React from 'react';
import './AttentionButton.css';

function AttentionButton() {
    const handleClick = () => {
        // Replace with the actual link or functionality
        window.location.href = 'https://chat.whatsapp.com/LvjUrO7l3UU9xLqVMsSkNg';
    };

    return (
        <div className="attention-button" onClick={handleClick}>
            Join SKi Club Group Chat!
        </div>
    );
}

export default AttentionButton;
