import React from "react";
import {Carousel} from "react-bootstrap";
import './Home.css'

function Home() {
    return (
        <Carousel>
            <Carousel.Item>
                <img src="trip_photo.jpg" text="First slide" alt="first"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src="yeti_flyer.jpg" text="Second slide" alt="second"/>
            </Carousel.Item>
            <Carousel.Item>
                <img src="trip_photo.jpg" text="Third slide" alt="third"/>
            </Carousel.Item>
        </Carousel>
    );
}


export default Home;