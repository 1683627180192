import React from "react";
import "./Footer.css";
import { CiInstagram } from "react-icons/ci";

const Footer = () => {
    return (
        <footer>
            <a href="/" className="footer__logo">
                FIU Ski Club
            </a>

            <ul className="permalinks">
                <li>
                    <a href="/">Home</a>
                </li>
                <li>
                    <a href="/about">About</a>

                </li>
                <li>
                    <a href="/gallery">Gallery</a>
                </li>
                <li>
                    <a href="/">Contact</a>
                </li>
            </ul>

            <div className="footer__socials">
                <a  href="https://www.instagram.com/fiuskinsnowboardclub/" target="_blank" rel="noreferrer">
                    <CiInstagram />
                </a>
            </div>

            <div className="footer__copyright">
                <small>&copy; FIU Ski Club. All rights reserved.</small>
            </div>
        </footer>
    );
};

export default Footer;