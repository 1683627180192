import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CustomNavbar from './components/navbar/CustomNavbar';
import About from "./components/about/About";
import Home from "./components/home/Home";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Gallery from "./components/gallery/Gallery";
import AttentionButton from "./components/attentionButton/AttentionButton";


const App = () => {
    return (
        <>
            <CustomNavbar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
            </Routes>
            <Footer/>
            <AttentionButton/>
        </>
    )
}

export default App