import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Carousel, Button } from 'react-bootstrap';
import './Gallery.css';

const trips = [
    {
        id: 1,
        title: 'Trip to Aspen',
        coverImage: '/path/to/aspen.jpg', // Replace with the path to your image
        description: 'A fun trip to Aspen with club members.',
        images: [
            '/path/to/aspen1.jpg',
            '/path/to/aspen2.jpg',
            '/path/to/aspen3.jpg',
        ],
    },
    {
        id: 2,
        title: 'Trip to Whistler',
        coverImage: '/path/to/whistler.jpg', // Replace with the path to your image
        description: 'Skiing adventures in Whistler.',
        images: [
            '/path/to/whistler1.jpg',
            '/path/to/whistler2.jpg',
            '/path/to/whistler3.jpg',
        ],
    },
    // Add more trips as needed
];

function Gallery() {
    const [selectedTrip, setSelectedTrip] = useState(null);

    const handleClose = () => setSelectedTrip(null);
    const handleShow = (trip) => setSelectedTrip(trip);

    return (
        <Container className="gallery-container">
            <h2 className="text-center my-4">Ski Trip Gallery</h2>
            <Row>
                {trips.map((trip) => (
                    <Col key={trip.id} md={4} className="mb-4">
                        <Card onClick={() => handleShow(trip)} className="trip-card">
                            <Card.Img variant="top" src={trip.coverImage} />
                            <Card.Body>
                                <Card.Title>{trip.title}</Card.Title>
                                <Card.Text>{trip.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {selectedTrip && (
                <Modal show={true} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedTrip.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel>
                            {selectedTrip.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={image}
                                        alt={`Slide ${index + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Container>
    );
}

export default Gallery;
