import React from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import './CustomNavbar.css';

function CustomNavbar() {
    return (
        <Navbar expand="lg">
            <Container fluid className="navbar-container">
                <div className="navbar-header">
                    <Navbar.Brand as={Link} to="/">
                        <img
                            alt="FIU Ski Club logo"
                            src="SkiClubLogo_transparent.png"
                            className="d-inline-block"
                        />
                        <span>FIU SKI CLUB</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                </div>
                <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                    <Nav className="ml-auto navbar-nav">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/about">About</Nav.Link>
                        <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
