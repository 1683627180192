import React from "react";
import { Container } from "react-bootstrap";

function About() {
  return (
    <Container
      style={{
        background: "",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "0 20px",
      }}
    >
      <h1 style={{ marginBottom: '20px' }}>Welcome to the FIU Ski & Snowboard Club!</h1>
      <p style={{ maxWidth: "800px", lineHeight: "2", fontSize: "18px" }}>
        We're a group of thrill-seekers who love to escape the sandy shores of
        South Florida and hit the snowy slopes. Whether you're a seasoned skier,
        a snowboard enthusiast, or a complete beginner, our club is all about
        embracing the winter spirit—right here from Miami! Our mission is to
        bring together a community of adventurous students who are passionate
        about winter sports. We organize epic trips to some of the best ski
        resorts, offer lessons for all skill levels, and host a variety of
        social events throughout the year. Even though we're based in the sunny
        Miami, we believe that the mountains aren't as far away as they seem. So
        grab your gear, and join us for unforgettable experiences, lifelong
        friendships, and, of course, a whole lot of powder! No snow? No problem.
        We're here to make it happen!
      </p>
    </Container>
  );
}

export default About;
