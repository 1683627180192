import React from "react";

function Contact() {
    return(
        <div>
            <h1>Contact</h1>
            <p>This is contact us page content</p>
        </div>
    );
}

export default Contact;